const setInputElement = (reset) => {
    let inputElement = null;

    if (reset) {
        inputElement = null;
    }

    return (element) => {
        if (element
            && (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA')
            && !element.value
            && !inputElement
        ) {
            inputElement = element;
        }

        return inputElement;
    }
}

let findInputElement = setInputElement();

const getInputElement = (element) => {
    const childElements = [...element.children];
    let inputElement = null;

    if (childElements.length > 0) {
        childElements.map((elem) => {
            let styleElement = getComputedStyle(elem);

            if (styleElement.display === 'none'
                || styleElement.visibility === 'hidden'
                || elem.style.display === 'none'
                || elem.style.visibility === 'hidden'
            ) {
                return;
            }

            inputElement = findInputElement(elem);

            if (elem.children && !inputElement) {
                getInputElement(elem);
            }
        });
    }

    if (!inputElement) {
        inputElement = findInputElement(element);
    }

    return inputElement;
};

const makeFocusInput = (element) => {
    // Время ожидания прогрузки компонентов внутри элемента
    let componentsLoadingTimeout = 100;

    // Используем setTimeout, чтобы выполнить функцию после прогрузки компонентов
    // для правильного нахождения элементов
    setTimeout(() => {
        let inputElement;

        findInputElement = setInputElement(true);

        if (element && (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA')) {
            inputElement = element;
        } else if (element && element.children) {
            inputElement = getInputElement(element);
        }
        if (inputElement) {
            inputElement.focus();
        }
    }, componentsLoadingTimeout)
};

export { makeFocusInput }





































































































































import '../scss/PopupAddProjectMember.scss';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { formatDate } from '@utility/formatDate';
import { IUserState } from '@store/modules/user/Interfaces';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { FormDataMaker } from '@utility/FormDataMaker';
import { IIndividualMember, IJuristicEntity } from "@store/modules/about-project/Interfaces";
import { makeFocusInput } from "@utility/makeFocusInput";

const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');
const NSAboutProject = namespace('storeAboutProject');
const NSProjectDocuments = namespace('storeProjectDocuments');
const NSProjectTeam = namespace('storeProjectTeam');

class InitialAddMemberData {
    name: string = '';
    position: string = '';
    user: object = {};
    role: object = {};
    company: object = {};
    document: object = {};
    employmentDate: string = '';
    activationDate: string = '';
    files: File[] = [];
}

class InitialAddMemberErrors {
    userComment: string = '';
    taskTakeDate: string = '';
    taskFiles: File[] = [];
}

@Component({
    name: 'AddMemberForm',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    }
})

export default class AddMemberForm extends Vue {
    $refs!: {
        form: HTMLFormElement,
        addMemberForm: HTMLElement,
    }
    @NSProjectDocuments.Getter('documents') documents!: IDocument[];
    @NSProjectTeam.Getter('projectTeam') projectTeam!: IMemberInfo[];
    @NSProject.State((state: IProjectState) => state.memberRoles) memberRoles!: IMemberRole[];
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSAboutProject.Getter('individualMembers') individualMembers!: IIndividualMember[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSAboutProject.Action(AboutProjectActions.A_CREATE_MEMBER) createNewMember!: (payload) => Promise<void>

    fields: any = new InitialAddMemberData();
    realtimeValidation = false;
    errors = {
        name: false,
        position: false,
        role: false,
        company: false,
        activationDate: false,
    };

    mounted() {
        makeFocusInput(this.$refs.addMemberForm);
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd' });
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get appId() {
        return this.projectData ? this.projectData.id : '';
    }

    get userId() {
        return this.userData && this.userData.userInfo ? this.userData.userInfo.id : '';
    }

    get companiesList(): any[] {
        return this.juristicEntities.map((item: IJuristicEntity) => ({
            uuid: item.id,
            label: item.title,
            value: item.title,
        }))
    }

    get documentsOtherList() {
        return this.documents
            .filter(document => document.category === "Прочее")
            .map(user => ({
                uuid: user.uuid,
                label: user.title,
                value: user.title,
            }))
    }

    get usersList() {
        const users = this.projectTeam
            .map(user => ({
                uuid: user.uuid,
                individualId: user.individualId,
                label: user.fullName,
                value: user.fullName,
            }))
        const individualMembers = this.individualMembers
            .filter(member => !member.userId)
            .map(member => ({
                uuid: '',
                individualId: member.id,
                label: member.name,
                value: member.name,
            }))
        return users.concat(individualMembers);
    }

    selectRole(role) {
        this.fields.role = role;
        this.checkValidation();
    }

    selectCompany(company) {
        this.fields.company = company;
        this.checkValidation();
    }

    selectDocument(document) {
        this.fields.document = document;
    }

    selectUser(user) {
        this.fields.name = user.value;
        this.fields.user = user;
    }

    selectFile(files: File[]) {
        this.fields.files = files;
    }

    validateFields() {
        this.errors.name = !this.fields.name;
        this.errors.position = !this.fields.position;
        this.errors.role = !this.fields.role.uuid;
        this.errors.company = !this.fields.company.uuid;
        this.errors.activationDate = !this.fields.activationDate;
        this.realtimeValidation = true;
    }

    checkSend() {
        this.validateFields();
        this.changeBtnDisabled(true);
        if (!this.errorExists) {
            const data = new FormDataMaker({
                appId: this.appId,
                name: this.fields.name,
                position: this.fields.position,
                roleId: this.fields.role.uuid,
                entityId: this.fields.company.uuid,
                activationDate: formatDate({ date: new Date(this.fields.activationDate), format: 'yyyy-mm-dd' }),
            }).make();
            if (this.fields.files && this.fields!.files![0]) {
                data.append('file', this.fields!.files![0]);
            }
            if (this.fields.document.uuid) {
                data.append('activationDocumentId', this.fields.document.uuid)
            }
            if (this.fields.user?.individualId) {
                data.append('individualId', this.fields.user.individualId)
            } else if (this.fields.user?.uuid) {
                data.append('userId', this.fields.user.uuid)
            }
            if (this.fields.employmentDate) {
                data.append(
                    'employmentDate',
                    formatDate({ date: new Date(this.fields.employmentDate), format: 'yyyy-mm-dd' })
                );
            }
            this.createNewMember(data)
                .then(() => {
                    this.$emit('confirm-add-member');
                    this.resetData();
            })
        }
        this.changeBtnDisabled(false);
    }

    resetData() {
        this.fields = new InitialAddMemberData();
        this.realtimeValidation = false;
        this.errors = {
            name: false,
            position: false,
            role: false,
            company: false,
            activationDate: false,
        };
    }

    changeBtnDisabled(val) {
        this.$emit('change-btn-disabled', val);
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}
